import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Fade from "react-reveal/Fade"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import OfficesNav from "./OfficesNav"
import OfficeAddress from "./OfficeAddress"
import ContactPreview from "../contacts/ContactPreview"
import WorkRelated from "../work/WorkRelated"
import Lonform from "../longform/Longform"
import FigureBox, { boxFormats } from "../../components/FigureBox"
import InlinePlayer from "../../components/InlinePlayer"
import Sharer from "../../components/Sharer"
import { StaticImage } from "gatsby-plugin-image"

const textColumnClassName = `office__single__body entry col-12 col-lg-9 col-xl-6 mx-auto`

class OfficeSingle extends Component {
  constructor(props) {
    super(props)
    this.data = props.data.office
  }

  contacts(contacts) {
    let content = []
    contacts.map((contact, index) => {
      if (contact.officeContact) {
        content.push(
          <ContactPreview
            key={index}
            contact={contact}
            details={true}
            col="col-12 col-lg-6 col-xl-6 mb-4"
          />
        )
      }
    })
    return content
  }

  photos() {
    let content = []
    let odd = this.props.data.office.photos.length % 2

    this.props.data.office.photos.map((photo, index) => {
      let classNames = []

      // on any photos
      classNames.push(`office__single__photos__photo col-12 col-md-6`)

      // first photo is always larger
      if (index === 0) {
        classNames.push(`col-lg-8 snap-to-left`)
      }

      // second smaller
      if (index === 1) {
        classNames.push(`col-lg-4`)
      }

      if (index === 2 && odd) {
        classNames.push(`col-lg-12`)
      } else if (index === 2 && !odd) {
        classNames.push(`col-lg-6`)
      }

      if (index > 2) {
        classNames.push(`col-lg-6`)
      }

      content.push(
        <div key={index} className={classNames.join(` `)}>
          <FigureBox
            source={photo}
            format={boxFormats.auto}
            caption={photo.description}
            type="inline"
          />
        </div>
      )
    })
    return content
  }

  render() {
    let {
      slug,
      officeName,
      officeImage,
      videoHeader,
      officeAbout,
      facebookUrl,
      twitterUrl,
      instagramUrl,
      contacts,
      work,
      selectedWork,
    } = this.props.data.office

    return (
      <Layout>
        <SEO title={`About us`} description={`What we stand for and where we do it.`} />
      <section className="office__single">

        {/* section header */}
        <header className="single__header">
          <p className="h4 text-center">
            <Link to="/about/office/delhi">About</Link>
          </p>
        </header>

        {/* all offices */}
        {this.props.pageContext &&
          this.props.pageContext.allOffices && (
            <Fade bottom delay={500} distance="25%">
              <header className="office__single__header">
                <h1 className="office__single__name is-text-center">
                  {officeName}
                </h1>
              </header>
            </Fade>
          )}

        {/* main image */}
        {officeImage &&
          !videoHeader && (
            <figure className="office__single__main_image">
              <Fade bottom duration={1200} delay={600} distance="10%">
                <FigureBox
                  source={officeImage}
                  format={boxFormats.sixteenByNine}
                />
              </Fade>
            </figure>
          )}

        {/* video loop header */}
        {videoHeader &&
          videoHeader.file && (
            <figure className="office__single__main_image">
              <Fade bottom duration={1200} delay={600} distance="10%">
                <InlinePlayer
                  src={videoHeader.file.url}
                  controls={false}
                  loop={true}
                  loopEvery={999}
                  fit={true}
                />
              </Fade>
            </figure>
          )}

        {/* office about infos */}
        <div className="container-fluid pl-lg-5 pl-xl-8 mb-3">
          <div className="row mb-md-3">
            <aside className="col-12 col-md-5 mb-4 office__single__address">
              {/* <OfficeAddress
                office={this.data}
                displayName={false}
                columns={null}
                link={true}
              />
              <OfficeAddress
                office={this.data}
                displayName={false}
                columns={null}
                link={true}
              /> */}
              <article className={`office__address null`}>      
                <div className="row">
                  <aside className="col-auto no-padding">
                    <StaticImage src="../../img/delhi/map-pin.png" alt="map" width="20" />
                  </aside>  
                  <aside className="col-9 col-sm-10 office__address__details">                    
                    <h3 className="office__name">
                      Delhi Office
                    </h3>                    
                    <div className="office__street-address">
                      17/48, Malcha Marg<br/>
                      Diplomatic Enclave<br/>
                      New Delhi 110021 India<br/>
                      +91 11 6136 9595<br/>
                    </div>                    
                  </aside>
                </div>
              </article>
              <article className={`office__address null`}>
                <div className="row mt-3">
                  <aside className="col-auto no-padding">
                    <StaticImage src="../../img/delhi/map-pin.png" alt="map" width="20" />
                  </aside>        
                  <aside className="col-9 col-sm-10 office__address__details">                    
                    <h3 className="office__name">
                      Mumbai Office
                    </h3>                    
                    <div className="office__street-address">
                      3rd Floor, Lloyds Centre Point Building.<br/>
                      PLOT - 1096A,<br/>
                      Apasaheb Marathe Marg,<br/>
                      Prabhadevi<br/>
                      Mumbai, Maharashtra, 400025<br/>
                      +91 22 3513 3356
                    </div>                    
                  </aside>
                </div>
              </article>
            </aside>

            <aside className="col-12 col-md-7 office__single__about entry pr-xl-6">
              {officeAbout && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: officeAbout.childMarkdownRemark.html,
                  }}
                />
              )}
            </aside>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="sep" />
            </div>
          </div>
        </div>

        <div className="container-fluid pl-lg-5 pl-xl-8 mb-2 mb-md-5">
          {/* office contacts */}
          <div className="row mb-md-5">
            <h5 className="h2 col-12 col-md-5 d-none d-md-block">
              Office Contacts
            </h5>

            <nav className="col-12 col-md-7 office__single__contacts row">
              {contacts && this.contacts(contacts)}
            </nav>
          </div>
        </div>

        <div className="pl-lg-3 pl-xl-6">
          <Sharer
            label={`Follow us on`}
            outlink={true}
            facebookUrl={facebookUrl}
            twitterUrl={twitterUrl}
            instagramUrl={instagramUrl}
          />
        </div>

        <div className="row office__single__longform mb-5">
          <Lonform
            rows={this.data.longform}
            textColumnClassName={textColumnClassName}
          />
        </div>

        {/* related work */}
        {!selectedWork &&
          work && (
            <WorkRelated
              allWork={work}
              slugToExclude={null}
              max={4}
              label="Work Highlights"
              randomize={false}
              link={`/work/office/${slug}/`}
            />
          )
        }

        {selectedWork && (
          <WorkRelated
            allWork={selectedWork}
            slugToExclude={null}
            max={4}
            label="Work Highlights"
            randomize={false}
            link={`/work/office/${slug}/`}
          />
        )}
      </section>
      </Layout>
    )
  }
}

export default OfficeSingle

//
// Each work fields
//
export const query = graphql`
  query OfficeQuery($slug: String!) {
    office: contentfulOffices(slug: { eq: $slug }) {
      ...officeFragment
    }
  }
`
